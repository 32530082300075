// ng-select theme
@import '~@ng-select/ng-select/themes/default.theme.css';

/* angular-cli file: src/styles.css */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

// Font configuration
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
$font-family-base: 'Roboto', sans-serif;

@import "./assets/customtheme/styles/colors.scss";
$primary:map-get($primaries, primary-600);
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

.top-bar{
  background-color: map-get($primaries,primary-800);
  color: #efefef;
}

// Icon component sizing
app-material-icon {
  display: inline-flex;
}

// Input with icon utility
.input-prefix-icon {
  position: relative;
  app-material-icon:first-child {
    color: #8d8d8d;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
  }
  .form-control {
    padding-left: 48px;
  }
}

.input-suffix-icon {
  position: relative;
  app-material-icon:last-child {
    color: #8d8d8d;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
  }
  .form-control {
    padding-right: 48px;
  }
}

.input-suffix-icon-error {
  app-material-icon:last-child {
      top: 30% !important;
  }
}

.text-over-darkprimary {
  color: white !important;
}
@each $name,
$color in $theme-colors {
  .btn-text-#{$name} {
    color: $color;
    border: none !important;
    &:hover,
    &:active,
    &:focus {
      color: darken($color, 10%) !important;
    }
  }
}
// Custom button align
.btn {
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  gap: 0.5rem;
}

.btn-gray {
  background-color: var(--bs-gray-200);
  color: var(--bs-gray-600);
  border-radius: 8px;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  &:active {
      background-color: var(--bs-gray-200);
      color: var(--bs-gray-600);
      border: var(--bs-gray-200);
  }
  .swap {
      transform: scaleY(-1);
  }
}

.btn-action {
  padding: 8px;
  border: transparent;
  &:active, &::after {
      border: transparent;
  }
  &:hover {
      background-color: var(--bs-gray-200);
  }
}


// Table
.table-container {
  border: solid #b3b3b3 1px;
  overflow: auto;
  max-height: 600px;
}

.sticky-header {
  th {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 100;

    div {
      padding: 0;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      div {
        cursor: pointer;
      }
    }

    div.byOrder {
      font-weight: bolder;
    }

    &:last-of-type {
      right: 0;
      top: 0;
    }
  }
}

.sticky-actions {
  tr {
    &:last-of-type {
      border-bottom-width: 0px;
    }
  }

  td {
    &:last-of-type {
      position: sticky;
      right: 0;
      top: 0;

      .dropdown-toggle {
        margin-left: auto;
      }
    }
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  // border: 1px solid map-get($grays, gray-300);

  th {
    background-color: #ededed;
    color: #272727;
    font-size: 14px;
  }

  tr {
    &:hover {
      background-color: #ededed;
    }
    &:last-of-type {
      border-bottom-width: 0px;
    }
  }


  th {
    padding: 0.5rem 0.5rem;
  }

  td {
    padding: 0.2rem 0.5rem;
  }

}

// Toogle icon remove
.dropdown-toggle {
  &::after {
    display: none !important;
  }

  &::before {
    display: none !important;
  }
}



// Custom font size
.fs-14 {
  font-size: 0.875rem;
}

.fs-12 {
  font-size: 0.75rem;
}
.fw-medium{
  font-weight: 600;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .body-container {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .body {
      // height: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .table-main-container {
        height: 100%;

        .table-container {
          height: 100%;
          min-height: 500px;
        }
      }
    }

    app-body {
      height: 100%;
    }
  }
}

div.switch-content {
  display: flex;
  align-items: center;
}

div.switch-btn {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin: 0 0.5rem 0 0;
  }

  label {
    margin: 0;
    padding-left: 0.5rem;
  }

  ui-switch {
    button {
      small {
        background: $primary;
      }
    }

    button.checked {
      background-color: map-get($primaries, primary-200) !important;

      small {
        background: $primary;
      }
    }
  }
}

.badge {
  padding: 0.5rem 1rem !important;
}

.form-check-input {
  padding: 0.5rem !important;
}

.form-check-label {
  font-weight: 500;
}

.active-row{
  td{
    &:first-of-type{
      .form-check-input{
        display: block;
      }
    }
  }
}

.selected-row {
  td {
    background-color: map-get($primaries, primary-50 ) !important;
  }
}

tr {
  td {
    &:first-of-type {
      .form-check-input {
        display: none;
      }
    }
  }
  &:hover {
    td {
      &:first-of-type {
        .form-check-input {
          display: block;
        }
      }
    }
  }
  &.menu-active {
    td {
      &:first-of-type {
        .form-check-input {
          display: block;
        }
      }
    }
  }
}

.calendar-icon {
  color: white;
}


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .cal-day-headers {
    margin-right: 14.5px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .w-lg-auto {
    width: auto !important
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}


.pagination {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-item {
    margin: 0 0.5rem 0 0.5rem;

    a {
      border: 1px solid map-get($primaries, primary-500);
      font-weight: 600;
      color: map-get($primaries, primary-500);
      border-radius: 8px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ng-select .ng-select-container {
  min-height: 48px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #CED4DA;
}

.ng-select.ng-select-single .ng-select-container {
  height: 48px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #CED4DA;
}

ng-select.is-invalid.ng-touched .ng-select-container {
  border-color: #dc3545 !important;
}

ng-select.ng-pristine.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
}

ng-select.is-invalid-in-table.ng-touched .ng-select-container {
  margin-top: 1.6rem;
}

ng-select.ng-pristine.is-invalid-in-table .ng-select-container {
  margin-top: 1.6rem;
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 3000;
}


// Angular Calendar Styles for Week View and Day View
.cal-week-view .cal-time-events {
  max-height: 60vh;
  overflow-y: auto;
}

.cal-day-columns {
  height: 100%;
}

// checkbox to show map
.map-checkbox {
  input[type="checkbox"] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.4);
    /* IE */
    -moz-transform: scale(1.4);
    /* FF */
    -webkit-transform: scale(1.4);
    /* Safari and Chrome */
    -o-transform: scale(1.4);
    /* Opera */
    transform: scale(1.4);
  }
}

// labels for markers in map component
.mark-label {
  background-color: #F7F9F9;
  padding: 4px;
  border-radius: 5px;
  margin-top: 64px;
}

.required-field::after {
  content: "*";
  color: red;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: var(--bs-primary-500);
  width: 48px !important;
  height: 48px !important;
}

.carousel-indicators button {
  background-color: var(--bs-primary-600) !important;
}

// custom ng-select
.custom.ng-select.ng-select-single .ng-select-container  {
  min-height: 38px;
  max-height: 38px;
  height: 38px;
}

button.dropdown-item {
  font-size: 14px;
  font-weight: 500;
  border-radius: 0 !important;
  &:hover {
    background-color: #ededed;
  }
  &.btn {
   justify-content: start !important; 
  }
}

.two-columns-container{
  .row{
    height: 100vh;
    .left-column{
      position: relative;
      background-color: var(--bs-primary-500);
      .logo{
        align-self: self-start;
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.checkbox-column {
  min-width: 50px;
  width: 50px;
}

.checkbox-column input {
  display: block; 
}

app-status-badge{
  padding-left: 0rem!important;
}

table tr td div {
  padding-left: 0rem!important;
}


@import "../node_modules/bootstrap/scss/bootstrap";

