$primaries: (
  "primary-50": #DFEEFB,
  "primary-100": #C0DDF7,
  "primary-200": #7CB9EF,
  "primary-300": #3C97E7,
  "primary-400": #1873C3,
  "primary-500": #104C80,
  "primary-600": #0D3E68,
  "primary-700": #0A2E4D,
  "primary-800": #061E32,
  "primary-900": #03101B,
);

$secondaries: (
  "secondary-50": #ffffff,
  "secondary-100": #f9f9f9,
  "secondary-200": #f2f2f2,
  "secondary-300": #ebebeb,
  "secondary-400": #e5e5e5,
  "secondary-500": #dfdfdf,
  "secondary-600": #b3b3b3,
  "secondary-700": #868686,
  "secondary-800": #5a5a5a,
  "secondary-900": #2d2d2d,
);

$tertiaries: (
  "tertiary-50": #f0f0f0,
  "tertiary-100": #e1e1e1,
  "tertiary-200": #c3c3c3,
  "tertiary-300": #a4a4a4,
  "tertiary-400": #868686,
  "tertiary-500": #7A7C7F,
  "tertiary-600": #515151,
  "tertiary-700": #393939,
  "tertiary-800": #282828,
  "tertiary-900": #141414,
);

$complementaries: (
  "complementary-50": #fff9e6,
  "complementary-100": #fff3cc,
  "complementary-200": #ffe699,
  "complementary-300": #ffdb66,
  "complementary-400": #ffd033,
  "complementary-500": #FFD700,
  "complementary-600": #cca200,
  "complementary-700": #997d00,
  "complementary-800": #665200,
  "complementary-900": #332900,
);

$successs: (
  "success-100": #e6f7ec,
  "success-200": #ccefd8,
  "success-500": #22b573,
  "success-600": #148e54,
);

$warnings: (
  "warning-100": #fef3e6,
  "warning-200": #fde7cc,
  "warning-500": #FC9002,
  "warning-600": #c25a00,
);

$dangers: (
  "danger-100": #f9e9ea,
  "danger-200": #f3d4d5,
  "danger-500": #C1272D,
  "danger-600": #991e24,
);

$infos: (
  "info-100": #e6f7fc,
  "info-200": #cceef9,
  "info-500": #29ABE2,
  "info-600": #1f85ab,
);

$grays: (
  "gray-100": #f2f2f2,
  "gray-200": #e5e5e5,
  "gray-300": #cccccc,
  "gray-400": #b3b3b3,
  "gray-500": #999999,
  "gray-600": #808080,
  "gray-700": #666666,
  "gray-800": #4d4d4d,
  "gray-900": #333333,
);

$theme-colors-shades-list: (
  "primary": $primaries,
  "secondary": $secondaries,
  "tertiary": $tertiaries,
  "complementary": $complementaries,
  "success": $successs,
  "danger": $dangers,
  "warning": $warnings,
  "info": $infos
);

$primary: map-get($primaries, "primary-500");
$secondary: map-get($secondaries, "secondary-500");
$tertiary: map-get($tertiaries, "tertiary-500");
$complementary: map-get($complementaries, "complementary-500");
$success: map-get($successs, "success-500");
$danger: map-get($dangers, "danger-500");
$warning: map-get($warnings, "warning-500");
$info: map-get($infos, "info-500");

@mixin css-customtheme-vars($values) {
  :root {
    @each $key, $value in $values {
      --bs-#{$key}: #{$value};
    }
  }
}

@include css-customtheme-vars($grays);
@include css-customtheme-vars($primaries);
@include css-customtheme-vars($complementaries);